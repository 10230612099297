import {JOB} from "../Shared/Helper";

export const ACCESS_BY = {
    "code": {
        "default_checked": true,
        "text": "Entrer le code à 6 caractères reçu par email",
        "type": "code",
        "disabled": false
    },
    "email": {
        "default_checked": false,
        "text": "Vous avez perdu votre code? Saisissez votre email ou votre numéro de téléphone ci-dessous et cliquez sur le lien qui vous sera envoyé",
        "type": "email",
        "disabled": false
    }
}

export const ACCESS_PATIENT_STRING = {
    "error": {
        "wrong_email": "Erreur dans l'adresse mail saisie.",
        "empty_email_or_office": "L'adresse email doit être remplie et le cabinet choisi.",
        "captcha": "Captcha incomplet ou erroné",
        "uncomplete_pin": "Le code pin doit être de 6 caractères.",
        "wrong_pin": "Erreur dans le code pin.",
        "connection_error": "Erreur lors de la connexion.",
        "error_occured": "Une erreur est survenue."
    },
    "payment": {
        "early_payment": {
            "sm": `Avance par ${JOB}`,
            "md": `Avance par ${JOB}`,
            "lg": `Règlement en avance par ${JOB}`,
        },
        "awaiting_payment": {
            "sm": "Attente",
            "md": "Attente",
            "lg": "Un paiement est en attente."
        },
        "invoice": {
            "sm": "Facture",
            "md": "Facture",
            "lg": "Facture"
        }
    },
    "alert": {
        "detected_pathology": {
            "sm": "Pathologie détectée.",
            "md": "Pathologie détectée.",
            "lg": "Une pathologie a été détectée, veuillez vous référer aux SMS que vous avez reçu."
        },
        "awaiting_processing": {
            "sm": "En analyse.",
            "md": "En cours de analyse.",
            "lg": "L'ophtalmologiste doit encore analyser votre dossier."
        },
        "awaiting_validation": {
            "sm": "En validation.",
            "md": "Validation en cours.",
            "lg": "La prescription est en cours de validation par l'ophtalmologue."
        }
    },
    "connexion": {
        "successful_connexion": "Connexion réussie, redirection en cours...",
        "email_sent": "Un email vous a était envoyé avec un lien de connexion."
    },
    "various_strings": {
        "hide_useless": {
            "sm":"Cacher sans prescription",
            "md":"Cacher sans prescription",
            "lg":"Cacher les rendez-vous sans prescriptions"
        }
    }
}