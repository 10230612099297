import React from 'react';
import {ActNature} from "../Shared/Enums/Enums";
import {t} from "../I18n/i18n"


function StatusIcon({status, act}) {

    if (status === -1) {
        return <i className="material-icons grey-text" title={t("EXAM_HISTORY.EXAM_NOT_COVER")}>do_not_disturb_on</i>
    }
    if (status === 0) {
        return <i className="material-icons grey-text" title={t("EXAM_HISTORY.NOT_TRANSMITTED")}>cancel</i>
    }
    if (status === 2) {
        return <i className="material-icons green-text" title={t("EXAM_HISTORY.VALIDATED_PRESCRIPTION")}>done</i>
    }
    if (status === 3) {
        return <i className="material-icons red-text" title={t("EXAM_HISTORY.ALERT")}>report_problem</i>
    }
    // Unhandled case
    if (status !== 1) {
        return null
    }
    if ((!act || (act.prix === 0 && act.nature !== ActNature.NURSE.code))) {
        return <i className="material-icons grey-text" title={t("EXAM_HISTORY.NOT_TRANSMITTED")}>cancel</i>
    }
    if (act && ![ActNature.TELEMEDICAL.code, ActNature.NURSE.code].includes(act.nature)) {
        return <i className="material-icons green-text" title={t("EXAM_HISTORY.EXAM_PERFORMED")}>done</i>
    }
    return <i className="material-icons orange-text" title={t("EXAM_HISTORY.VALIDATION_IN_PROGRESS")}>more_time</i>

}

export default StatusIcon;