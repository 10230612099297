import React, {useEffect, useRef} from 'react';
import MoreButton from "./MoreButton";

function ResponsiveMoreButton(props) {
    let {options} = props
    const [displayableButtonsNumber, setDisplayableButtonsNumber] = React.useState(0);
    const resizableDiv = useRef(null);


    useEffect(() => {
        // const div = document.getElementById('resizableDiv');
        const resizeObserver = new ResizeObserver(() => {
            // This callback will be executed whenever the div is resized
            handleDivResize();
        });
        resizeObserver.observe(resizableDiv.current);

        return () => resizeObserver.disconnect(resizableDiv.current);
    }, []);

    function handleDivResize() {
        const width = resizableDiv.current.clientWidth;
        const buttonsNumber = Math.floor(width / 200);
        setDisplayableButtonsNumber(buttonsNumber);
    }

    options = options.filter((option) => option !== null)
    return <div style={{display: "flex", gap: 20, width: "100%"}}>
        <div ref={resizableDiv} id="resizableDiv" style={{
            display: "flex",
            gap: 20,
            width: "100%",
            justifyContent: "flex-end"
        }}>
            {options.slice(0, displayableButtonsNumber).map((button, index) => <div key={index}>{button}</div>)}
        </div>
        {displayableButtonsNumber < options.length ? <MoreButton {...props} options={options.slice(displayableButtonsNumber, options.length)}/> : null}
    </div>
}

export default ResponsiveMoreButton;