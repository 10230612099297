import Validator from "../../Shared/Validator";
import React from "react";
import axios from "axios";
import {wrapper} from "../../I18n/i18n";

const AskForReworkButtonOrtho = ({appointment_id, reworkable, onValidation, big}) => {
    const {t} = wrapper("EXAM_HISTORY.REWORK")

    const rework_appointment = () => {
        axios.patch(`/patients/appointment/rework/${appointment_id}`).then(() => {
            onValidation(appointment_id)
        })
    }

    const renderButton = () => {
        if(big) return <a style={{borderRadius: "10px"}} className="btn-large  orange accent-4 truncate-nohide z-depth-0 truncate">
            {t("REWORK")}
        </a>
        return <a className={`btn-small  orange accent-4 z-depth-0 truncate ${reworkable || "disabled"}`}>
            <i className="material-icons left hide-on-med-and-down text-lighten-2">undo</i>
            <i className="material-icons hide-on-large-only">undo</i>
            <span>{t("REWORK")}</span>
        </a>
    }

    return <Validator id={`ask_for_rework_${appointment_id}`}
                      onValidation={() => rework_appointment()}
                      text={t("ORTHOPTIST.VALIDATION_TEXT")}
                      detail={t("ORTHOPTIST.VALIDATION_DETAIL")}
                      validationColor="blue darken-2" validationIcon="undo"
                      validationText={t("REWORK")}
                      abortColor="black" abortIcon="close" warning
    >
        {renderButton()}
    </Validator>
};

AskForReworkButtonOrtho.defaultProps = {
    reworkable: true,
    onValidation: () => {
    },
    big: false
}

export default AskForReworkButtonOrtho;