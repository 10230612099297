import React from 'react';
import {wrapper} from "../../I18n/i18n"
import Validator from "../../Shared/Validator";

function EditAppointmentButton({orthoptist_signed, appointment_id, appointment_status}) {
    const {t} = wrapper("EXAM_HISTORY.EDIT")

    const buttonContent = () => {
        if(appointment_status > 1) return {icon: "preview", text: t("VIEW")}
        else return {icon: "edit", text: t("TITLE")}

    }
    if (!orthoptist_signed || appointment_status > 1) return null

    const redirect = () => {
        window.location.href = `/mesure/${appointment_id}`
    }


    const renderButton = () => {
        return <a className="btn-small blue darken-2 z-depth-0"
                  href={`/mesure/${appointment_id}`}
                  title={t("WARNING")}>
            <i className="material-icons left hide-on-med-and-down">{buttonContent().icon}</i>
            <i className="material-icons hide-on-large-only">{buttonContent().icon}</i>
            <span className="hide-on-med-and-down truncate">{buttonContent().text}</span>
        </a>
    }

    return <Validator id={`edit_appointment_${appointment_id}`}
                      onValidation={redirect}
                      text={t("VALIDATION_TEXT")}
                      detail={t("VALIDATION_DETAIL")}
                      validationColor="blue darken-2" validationIcon="edit"
                      validationText={t("VALIDATION")}
                      abortColor="black" abortIcon="close" warning
    >
        {renderButton()}
    </Validator>
}

export default EditAppointmentButton;